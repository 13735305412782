<template>
  <div class="ActivityComments__Component">
    <div class="ActivityComments__Component__Comments">
      <div
        class="ActivityComments__Component__Comments__Toggle"
        @click="isOpen = !isOpen"
      >
        <div
          class="ActivityComments__Component__Comments__Icon"
          :class="{ open: isOpen }"
        >
          <b-icon icon="chevron-up" />
        </div>
        תגובות ({{ comments.length }})
      </div>
      <div class="ActivityComments__Component__Comments__List" v-if="isOpen">
        <div
          class="ActivityComments__Component__Comments__List__Item"
          v-for="comment in comments"
          :key="comment.Id"
        >
          <ActivityComment
            @onDelete="deleteComment"
            :item="comment"
            :entityType="entityType"
            :entityId="entityId"
            :id="id"
          />
        </div>
      </div>
    </div>
    <div v-if="!commentMode" class="AddComment" @click="commentMode = true">
      <b-icon icon="comment-processing" size="is-small" />
      <a>הוסף תגובה</a>
    </div>
    <div class="ActivityComments__Component__Editor" v-if="commentMode">
      <NewActivityEditor ref="editor" animation="''" />
      <div class="Activity__Item__Box__Content__Text__Editor__Actions">
        <b-button :loading="isSaving" @click="newComment()" class="Save"
          >שמור</b-button
        >
        <b-button
          :disabled="isSaving"
          @click="commentMode = false"
          class="Cancel"
          >בטל</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import EntityItemsService from "@/services/EntityItemsService";
import NewActivityEditor from "./NewActivityEditor.vue";
import ActivityComment from "./ActivityComment.vue";

export default {
  name: "ActivityComments",
  props: {
    id: Number,
    entityType: String,
    entityId: String,
    comments: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.comments.sort(
      (a, b) => new Date(a.CreateDate) - new Date(b.CreateDate)
    );
  },
  components: {
    NewActivityEditor,
    ActivityComment,
  },
  data() {
    return {
      isOpen: false,
      commentMode: false,
      isSaving: false,
    };
  },
  methods: {
    deleteComment(id) {
      const index = this.comments.findIndex((i) => i.Id === id);
      if (index > -1) {
        this.comments.splice(index, 1);
      }
    },
    newComment() {
      this.isSaving = true;
      const body = this.$refs.editor.getHTML();
      EntityItemsService.addComment(this.entityType, this.entityId, this.id, {
        Body: body,
      })
        .then((r) => {
          this.comments.push(r.data);
          this.commentMode = false;
          this.isOpen = true;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ActivityComments__Component {
  padding: 8px 10px;
  display: flex;
  flex-direction: column;

  .ActivityComments__Component__Comments__List__Item {
    padding: 15px 10px;
    margin: 10px 0;
    border: 1px solid #d2d2d2;
    padding: 15px 10px;
  }

  .ActivityComments__Component__Editor {
    .NewTask__Component__Editor_Inner {
      background-color: #edfaff;
      border: 1px solid #cecece;

      ::v-deep .ProseMirror {
        height: 100px;
      }
    }

    .Activity__Item__Box__Content__Text__Editor__Actions {
      margin-top: 10px;

      button {
        border: none;
        background-color: transparent;
        padding: 5px 10px;
        cursor: pointer;
        background-color: #486480;
        height: unset;
        line-height: normal;
        font-size: 14px;

        &:hover {
          background-color: #597794;
        }

        &:active {
          transform: scale(0.97);
        }

        &.Save {
          color: white;
          border-radius: 3px;
          transition: transform 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        &.Cancel {
          color: white;
          border-radius: 3px;
          margin-right: 5px;
        }
      }
    }
  }

  .ActivityComments__Component__Comments {
    .ActivityComments__Component__Comments__Toggle {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #2e7086;
      }
    }

    .ActivityComments__Component__Comments__Icon {
      transform: rotate(-90deg);
      margin-inline-start: -5px;
      color: #0091ae;
      transition: transform 0.1s linear;

      &.open {
        transform: rotate(-180deg) !important;
      }

      & ::v-deep .mdi::before {
        font-size: 20px;
      }
    }
  }

  .AddComment {
    span {
      color: #569cc5;
      margin-inline-end: 5px;
    }
    a {
      color: #0091ae;
      border: none;
      background: transparent;
      cursor: pointer;
      padding: 0;
      margin: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
