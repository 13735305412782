var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ActivityComments__Component" }, [
    _c("div", { staticClass: "ActivityComments__Component__Comments" }, [
      _c(
        "div",
        {
          staticClass: "ActivityComments__Component__Comments__Toggle",
          on: {
            click: function($event) {
              _vm.isOpen = !_vm.isOpen
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "ActivityComments__Component__Comments__Icon",
              class: { open: _vm.isOpen }
            },
            [_c("b-icon", { attrs: { icon: "chevron-up" } })],
            1
          ),
          _vm._v(" תגובות (" + _vm._s(_vm.comments.length) + ") ")
        ]
      ),
      _vm.isOpen
        ? _c(
            "div",
            { staticClass: "ActivityComments__Component__Comments__List" },
            _vm._l(_vm.comments, function(comment) {
              return _c(
                "div",
                {
                  key: comment.Id,
                  staticClass:
                    "ActivityComments__Component__Comments__List__Item"
                },
                [
                  _c("ActivityComment", {
                    attrs: {
                      item: comment,
                      entityType: _vm.entityType,
                      entityId: _vm.entityId,
                      id: _vm.id
                    },
                    on: { onDelete: _vm.deleteComment }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e()
    ]),
    !_vm.commentMode
      ? _c(
          "div",
          {
            staticClass: "AddComment",
            on: {
              click: function($event) {
                _vm.commentMode = true
              }
            }
          },
          [
            _c("b-icon", {
              attrs: { icon: "comment-processing", size: "is-small" }
            }),
            _c("a", [_vm._v("הוסף תגובה")])
          ],
          1
        )
      : _vm._e(),
    _vm.commentMode
      ? _c(
          "div",
          { staticClass: "ActivityComments__Component__Editor" },
          [
            _c("NewActivityEditor", {
              ref: "editor",
              attrs: { animation: "''" }
            }),
            _c(
              "div",
              {
                staticClass:
                  "Activity__Item__Box__Content__Text__Editor__Actions"
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "Save",
                    attrs: { loading: _vm.isSaving },
                    on: {
                      click: function($event) {
                        return _vm.newComment()
                      }
                    }
                  },
                  [_vm._v("שמור")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "Cancel",
                    attrs: { disabled: _vm.isSaving },
                    on: {
                      click: function($event) {
                        _vm.commentMode = false
                      }
                    }
                  },
                  [_vm._v("בטל")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }