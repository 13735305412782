var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Task__Entity" },
    [
      _c("div", { staticClass: "Task__Entity__Related__Item" }, [
        _c("span", [_vm._v("פריט משוייך")]),
        _c(
          "div",
          { staticClass: "Task__Entity__Related__Item__TheItem" },
          [
            _c(
              "router-link",
              { attrs: { to: "/" + _vm.entityType + "s/" + _vm.id.EntityId } },
              [
                _c("span", [_vm._v(_vm._s(_vm.entityDisplayName))]),
                _vm._v(" - "),
                _c("span", [_vm._v(_vm._s(_vm.entityKeyValue))])
              ]
            )
          ],
          1
        )
      ]),
      _c("ActivityItemTask", {
        attrs: {
          "is-open": true,
          item: _vm.id,
          entityType: _vm.entityType,
          entityId: _vm.id.EntityId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }