var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ActivityItemTask__Component" }, [
    _vm.model.Title
      ? _c(
          "div",
          {
            staticClass: "Activity__Item__Box__Content__Text__Title",
            class: { complete: _vm.model.IsComplete }
          },
          [
            _c(
              "button",
              { on: { click: _vm.complete } },
              [
                _c("b-icon", {
                  attrs: {
                    icon: _vm.model.IsComplete
                      ? "check-circle"
                      : "check-circle-outline"
                  }
                })
              ],
              1
            ),
            !_vm.editTitleMode
              ? _c(
                  "span",
                  {
                    staticClass:
                      "Activity__Item__Box__Content__Text__Editor --Title mr-1",
                    class: { open: _vm.isOpen },
                    on: { click: _vm.editTitle }
                  },
                  [_vm._v(_vm._s(_vm.model.Title))]
                )
              : _vm._e(),
            _vm.editTitleMode
              ? _c(
                  "div",
                  [
                    _c("NewActivityEditor", {
                      ref: "editorTitle",
                      attrs: { content: _vm.item.Title }
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Activity__Item__Box__Content__Text__Editor__Actions"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "Save",
                            attrs: { loading: _vm.isLoading },
                            on: {
                              click: function($event) {
                                return _vm.updateTitle()
                              }
                            }
                          },
                          [_vm._v("שמור")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "Cancel",
                            attrs: { disabled: _vm.isLoading },
                            on: {
                              click: function($event) {
                                _vm.editTitleMode = false
                              }
                            }
                          },
                          [_vm._v("בטל")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm.isOpen
      ? _c("div", { staticClass: "ActivityItemTask__Component__Edit" }, [
          _c("div", { staticClass: "NewTask__Component__Reminder" }, [
            _c("div", { staticClass: "NewTask__Component__Reminder__Fields" }, [
              _c(
                "div",
                { staticClass: "ml-6" },
                [
                  _c("FieldInlineDateTime", {
                    attrs: {
                      label: "עד תאריך",
                      "hide-hover": true,
                      position: "is-bottom-left"
                    },
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.model.DueDate,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "DueDate", $$v)
                      },
                      expression: "model.DueDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mr-6" },
                [
                  _c("FieldInlineDateTime", {
                    attrs: {
                      label: "תזכורת",
                      "hide-hover": true,
                      position: "is-bottom-left"
                    },
                    on: { onChange: _vm.onChange },
                    model: {
                      value: _vm.model.EmailReminderDate,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "EmailReminderDate", $$v)
                      },
                      expression: "model.EmailReminderDate"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "NewTask__Component__Attributes" }, [
            _c(
              "div",
              { staticClass: "Field ml-6" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "סוג",
                    clearable: false,
                    filterable: false,
                    optionsAsync: _vm.getTaskTypes
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.TaskType,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "TaskType", $$v)
                    },
                    expression: "model.TaskType"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "דחיפות",
                    clearable: false,
                    filterable: false,
                    optionsAsync: _vm.getPriorityTypes,
                    "null-is-value": true
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.Priority,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "Priority", $$v)
                    },
                    expression: "model.Priority"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Field" },
              [
                _c("FieldInlineSelect", {
                  attrs: {
                    "hide-hover": true,
                    label: "שיוך משתמש",
                    clearable: false,
                    filterable: false,
                    optionsAsync: _vm.getUsers
                  },
                  on: { onChange: _vm.onChange },
                  model: {
                    value: _vm.model.AssignedUserId,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "AssignedUserId", $$v)
                    },
                    expression: "model.AssignedUserId"
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm.isOpen
      ? _c(
          "div",
          {
            staticClass: "Activity__Item__Box__Content__Text__Editor__Wrapper"
          },
          [
            !_vm.editBodyMode
              ? _c("div", {
                  staticClass: "Activity__Item__Box__Content__Text__Editor",
                  class: { open: _vm.isOpen },
                  domProps: { innerHTML: _vm._s(_vm.model.Body) },
                  on: {
                    click: function($event) {
                      return _vm.editBody()
                    }
                  }
                })
              : _vm._e(),
            _vm.editBodyMode
              ? _c(
                  "div",
                  [
                    _c("NewActivityEditor", {
                      ref: "editorBody",
                      attrs: { content: _vm.model.Body }
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "Activity__Item__Box__Content__Text__Editor__Actions"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "Save",
                            attrs: { loading: _vm.isLoading },
                            on: {
                              click: function($event) {
                                return _vm.updateBody()
                              }
                            }
                          },
                          [_vm._v("שמור")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "Cancel",
                            attrs: { disabled: _vm.isLoading },
                            on: {
                              click: function($event) {
                                _vm.editBodyMode = false
                              }
                            }
                          },
                          [_vm._v("בטל")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm.item.Comments && _vm.item.Comments.length
      ? _c(
          "div",
          { staticClass: "comments" },
          [
            _c("ActivityComments", {
              attrs: {
                comments: _vm.item.Comments,
                entityType: _vm.entityType,
                entityId: _vm.entityId.toString(),
                id: _vm.item.Id
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }