<template>
  <div class="Task__Entity">
    <div class="Task__Entity__Related__Item">
      <span>פריט משוייך</span>
      <div class="Task__Entity__Related__Item__TheItem">
        <router-link :to="`/${entityType}s/${id.EntityId}`">
          <span>{{ entityDisplayName }}</span> -
          <span>{{ entityKeyValue }}</span>
        </router-link>
      </div>
    </div>
    <ActivityItemTask
      :is-open="true"
      :item="id"
      :entityType="entityType"
      :entityId="id.EntityId"
    />
  </div>
</template>

<script>
import ActivityItemTask from "@/components/ActivityItemTask.vue";
import ReportService from "@/services/ReportService";
import VehicleService from "@/services/VehicleService";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import TransferItemsService from "@/services/TransferItemsService";
import DriverService from "@/services/DriverService";

export default {
  name: "TaskEntity",
  props: ["id"],
  computed: {
    entityType() {
      switch (this.id.EntityType) {
        case 1:
          return "Report";
        case 2:
          return "Vehicle";
        case 3:
          return "Profile";
        case 4:
          return "Accident";
        case 5:
          return "Transfer";
        case 6:
          return "DriverTransfer";
        case 7:
          return "VehicleContract";
        case 8:
          return "DriverContract";
        case 9:
          return "Client";
        case 10:
          return "Driver";
        default:
          return null;
      }
    },
    entityDisplayName() {
      switch (this.id.EntityType) {
        case 1:
          return "דוח";
        case 2:
          return "רכב";
        case 3:
          return "פרופיל";
        case 4:
          return "תאונה";
        case 5:
          return "הסבה";
        case 6:
          return "הסבת נהג";
        case 7:
          return "חוזה רכב";
        case 8:
          return "חוזה נהג";
        case 9:
          return "לקוח";
        case 10:
          return "נהג";
        default:
          return null;
      }
    },
    entityKeyValue() {
      switch (this.id.EntityType) {
        case 1:
          return this.data?.ReportNumber;
        case 2:
          return this.data?.PlateNumber;
        case 3:
          return "פרופיל";
        case 4:
          return "תאונה";
        case 5:
          return this.data?.Report.ReportNumber;
        case 6:
          return "הסבת נהג";
        case 7:
          return "חוזה רכב";
        case 8:
          return "חוזה נהג";
        case 9:
          return this.data?.Client.Name;
        case 10:
          return this.data?.Name;
        default:
          return null;
      }
    },
    entityGetMethod() {
      switch (this.id.EntityType) {
        case 1:
          return ReportService.getReport;
        case 2:
          return VehicleService.getVehicle;
        case 3:
          return "Profile";
        case 4:
          return "Accident";
        case 5:
          return TransferItemsService.getTransfer;
        case 6:
          return TransferItemsService.getDriverTransfer;
        case 7:
          return "VehicleContract";
        case 8:
          return "DriverContract";
        case 9:
          return LeasingCompaniesService.getClient;
        case 10:
          return DriverService.getDriver;
        default:
          return null;
      }
    },
  },
  data() {
    return {
      data: null,
    };
  },
  components: {
    ActivityItemTask,
  },
  created() {
    this.getItem();
  },
  methods: {
    getItem() {
      // console.log("this.id.EntityId:", this.id.EntityId);
      this.entityGetMethod(this.id.EntityId).then((r) => {
        this.data = r.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.Task__Entity__Related__Item {
  padding: 10px;

  span {
    font-size: 16px;
  }

  .Task__Entity__Related__Item__TheItem {
    border: 2px solid #00b0b2;
    padding: 10px;
    margin: 15px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      border-color: #0aced0 !important;
    }
  }
}
.ActivityItemTask__Component {
  ::v-deep {
    .Activity__Item__Box__Content__Text__Title {
      padding-top: 15px;
    }
    .NewTask__Component__Reminder__Fields {
      flex-direction: column;

      & > div {
        width: 100%;
        margin: 0 !important;
      }
    }
    .NewTask__Component__Attributes {
      flex-direction: column;
      & > div {
        margin: 0 !important;
      }
    }
    .Activity__Item__Box__Content__Text__Editor__Wrapper {
      padding: 0 20px;
    }
    .NewTask__Component__Editor_Inner {
      padding: 0px;
      border: 2px solid #2fc9e8;
      border-radius: 3px;
      margin-bottom: 10px;

      .ProseMirror {
        min-height: 100px;
      }
    }
  }
}
</style>
