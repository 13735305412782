var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ActivityComment__Component" }, [
    _c(
      "div",
      { staticClass: "ActivityComment__Component__Avatar" },
      [_c("b-icon", { attrs: { icon: "account" } })],
      1
    ),
    _c("div", { staticClass: "ActivityComment__Component__Box" }, [
      _c("div", { staticClass: "ActivityComment__Component__Box__Header" }, [
        _c("div", { staticClass: "ActivityComment__Component__Box__User" }, [
          _c("strong", [_vm._v(_vm._s(_vm.item.CreatorName))]),
          _vm._v(" הגיב לפוסט זה "),
          _c(
            "div",
            { staticClass: "ActivityComment__Component__Box__User__Actions" },
            [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.editMode = true
                    }
                  }
                },
                [_vm._v("עריכה")]
              ),
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.deleteComment($event)
                    }
                  }
                },
                [_vm._v("מחיקה")]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "ActivityComment__Component__Box__Date" }, [
          _vm._v(" " + _vm._s(_vm.parsedDate) + " ")
        ])
      ]),
      _c("div", { staticClass: "ActivityComment__Component__Box__Content" }, [
        !_vm.editMode
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.item.Body) } })
          : _vm._e(),
        _vm.editMode
          ? _c(
              "div",
              { staticClass: "ActivityComment__Component__Box__Content__Edit" },
              [
                _c("NewActivityEditor", {
                  ref: "editor",
                  attrs: { content: _vm.item.Body, animation: "''" }
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "ActivityComment__Component__Box__Content__Edit__Actions"
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "Save",
                        attrs: { loading: _vm.isLoading },
                        on: { click: _vm.update }
                      },
                      [_vm._v("שמור")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "Cancel",
                        attrs: { disabled: _vm.isLoading },
                        on: {
                          click: function($event) {
                            _vm.editMode = false
                          }
                        }
                      },
                      [_vm._v("בטל")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }