<template>
  <div class="ActivityItemTask__Component">
    <div
      :class="{ complete: model.IsComplete }"
      class="Activity__Item__Box__Content__Text__Title"
      v-if="model.Title"
    >
      <button @click="complete">
        <b-icon
          :icon="model.IsComplete ? 'check-circle' : 'check-circle-outline'"
        />
      </button>
      <span
        class="Activity__Item__Box__Content__Text__Editor --Title mr-1"
        :class="{ open: isOpen }"
        v-if="!editTitleMode"
        @click="editTitle"
        >{{ model.Title }}</span
      >
      <div v-if="editTitleMode">
        <NewActivityEditor ref="editorTitle" :content="item.Title" />
        <div class="Activity__Item__Box__Content__Text__Editor__Actions">
          <b-button :loading="isLoading" @click="updateTitle()" class="Save"
            >שמור</b-button
          >
          <b-button
            :disabled="isLoading"
            @click="editTitleMode = false"
            class="Cancel"
            >בטל</b-button
          >
        </div>
      </div>
    </div>
    <div class="ActivityItemTask__Component__Edit" v-if="isOpen">
      <div class="NewTask__Component__Reminder">
        <div class="NewTask__Component__Reminder__Fields">
          <div class="ml-6">
            <FieldInlineDateTime
              label="עד תאריך"
              @onChange="onChange"
              :hide-hover="true"
              position="is-bottom-left"
              v-model="model.DueDate"
            />
            <!-- <FieldInlineDate
              :hide-hover="true"
              label="עד תאריך"
              @onChange="onChange"
              v-model="model.DueDate"
            />

            <FieldInlineTime
              :hide-hover="true"
              label="שעה"
              @onChange="onChange"
              v-model="model.DueDate"
            /> -->
          </div>
          <div class="mr-6">
            <FieldInlineDateTime
              label="תזכורת"
              @onChange="onChange"
              :hide-hover="true"
              position="is-bottom-left"
              v-model="model.EmailReminderDate"
            />
            <!-- <FieldInlineDate
              :hide-hover="true"
              label="תזכורת"
              @onChange="onChange"
              v-model="model.EmailReminderDate"
            />

            <FieldInlineTime
              :hide-hover="true"
              label="שעה"
              :value="new Date(value)"
              @onChange="onChange"
              v-model="model.EmailReminderDate"
            /> -->
          </div>
        </div>
      </div>
      <div class="NewTask__Component__Attributes">
        <div class="Field ml-6">
          <FieldInlineSelect
            :hide-hover="true"
            label="סוג"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getTaskTypes"
            @onChange="onChange"
            v-model="model.TaskType"
          />
        </div>
        <div class="Field">
          <FieldInlineSelect
            :hide-hover="true"
            label="דחיפות"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getPriorityTypes"
            :null-is-value="true"
            @onChange="onChange"
            v-model="model.Priority"
          />
        </div>
        <div class="Field">
          <FieldInlineSelect
            :hide-hover="true"
            label="שיוך משתמש"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getUsers"
            @onChange="onChange"
            v-model="model.AssignedUserId"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isOpen"
      class="Activity__Item__Box__Content__Text__Editor__Wrapper"
    >
      <div
        v-if="!editBodyMode"
        class="Activity__Item__Box__Content__Text__Editor"
        :class="{ open: isOpen }"
        v-html="model.Body"
        @click="editBody()"
      ></div>

      <div v-if="editBodyMode">
        <NewActivityEditor ref="editorBody" :content="model.Body" />
        <div class="Activity__Item__Box__Content__Text__Editor__Actions">
          <b-button :loading="isLoading" @click="updateBody()" class="Save"
            >שמור</b-button
          >
          <b-button
            :disabled="isLoading"
            @click="editBodyMode = false"
            class="Cancel"
            >בטל</b-button
          >
        </div>
      </div>
    </div>
    <div class="comments" v-if="item.Comments && item.Comments.length">
      <ActivityComments
          :comments="item.Comments"
          :entityType="entityType"
          :entityId="entityId.toString()"
          :id="item.Id"
        />
    </div>
  </div>
</template>

<script>
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";
// import FieldInlineDate from "@/components/Fields/FieldInlineDate.vue";
// import FieldInlineTime from "@/components/Fields/FieldInlineTime.vue";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import EntityEnumsService from "@/services/EntityEnumsService";
import ProfileService from "@/services/ProfileService";
import EntityItemsService from "@/services/EntityItemsService";
import NewActivityEditor from "./NewActivityEditor.vue";
// import ActivityItem from "./ActivityItem.vue";
import ActivityComments from "./ActivityComments.vue";

export default {
  name: "ActivityItemTask",
  props: ["item", "isOpen", "entityType", "entityId"],
  components: {
    NewActivityEditor,
    // FieldInlineDate,
    // FieldInlineTime,
    FieldInlineSelect,
    FieldInlineDateTime,
    // ActivityItem,
    ActivityComments
},
  data() {
    return {
      isLoading: false,
      editTitleMode: false,
      editBodyMode: false,
      model: {},
    };
  },
  created() {
    this.model = { ...this.item };
    console.log(this.model);
  },
  methods: {
    onChange(isEmpty) {
      this.hasContent = !isEmpty;
      this.update();
    },
    editBody() {
      this.editBodyMode = true;
    },
    editTitle() {
      this.editTitleMode = true;
    },
    complete() {
      this.model.IsComplete = !this.model.IsComplete;
      EntityItemsService.complete(
        this.entityType,
        this.entityId,
        this.item.Id,
        this.model.IsComplete
      );
    },
    getTaskTypes() {
      return () =>
        EntityEnumsService.getTaskTypes().then((r) =>
          r.data.map((i) => ({ Value: i.Id, Text: i.Name }))
        );
    },
    getPriorityTypes() {
      return () =>
        EntityEnumsService.getTaskPriorities().then((r) => {
          const data = r.data.map((i) => ({ Value: i.Id, Text: i.Name }));
          data.unshift({ Value: null, Text: "ללא" });
          return data;
        });
    },
    getUsers() {
      if (this.isAdminWithoutProfileId) {
        return () =>
        ProfileService.adminUsersTask().then((r) => {
          const data = r.data.map((u) => ({
            Value: u.Id,
            Text: u.FullName || u.UserName,
          }));
          this.assignedUserId = data.find((u) => u.Text === 'info@betterway.co.il')?.Value;
          this.isValid();
          return data;
      });
    }
      return () =>
        ProfileService.usersTask().then((r) => {
          const data = r.data.map((u) => ({
            Value: u.Id,
            Text: u.FullName || u.UserName,
          }));
          this.assignedUserId = r.data.find((u) => u.IsMe)?.Value;
          return data;
      });
    },
    updateTitle() {
      this.isLoading = true;
      const title = this.$refs.editorTitle
        .getHTML()
        .replace("<p>", "")
        .replace("</p>", "");
      this.model.Title = title;
      EntityItemsService.editTask(
        this.entityType,
        this.entityId,
        this.item.Id,
        this.model
      )
        .then(() => {
          this.item.Title = title;
          this.editTitleMode = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateBody() {
      const body = this.$refs.editorBody.getHTML();
      this.model.Body = body;
      EntityItemsService.editTask(
        this.entityType,
        this.entityId,
        this.item.Id,
        this.model
      ).then(() => {
        this.item.Body = body;
        this.editBodyMode = false;
      });
    },
    update() {
      this.isLoading = true;
      EntityItemsService.editTask(
        this.entityType,
        this.entityId,
        this.item.Id,
        this.model
      )
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: "ההערה נשמרה!",
            duration: 4000,
          });
        })
        .catch(() => {
          if (!this.model.Title) {
            this.$buefy.toast.open({
              type: "is-danger",
              message: "חסרה הכותרת!",
              duration: 8000,
            });
          }
          this.$buefy.toast.open({
            type: "is-danger",
            message: "בעיה בשמירה!",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    isAdminWithoutProfileId() {
      return !this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    }
  }
};
</script>

<style scoped lang="scss">
.ActivityItemTask__Component__Edit {
  padding: 15px 0;
  border-top: 1px solid #d8d8d8;
}
.Activity__Item__Box__Content__Text__Title {
  display: flex;
  align-items: start;
  padding-bottom: 15px;

  ::v-deep {
    .NewTask__Component__Editor__Actions {
      display: none;
    }
    .ProseMirror {
      min-height: unset !important;
      padding: 2px 5px;
    }
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .icon {
    color: silver;
  }
  &.complete {
    .icon {
      color: #00bda5;
    }
    span {
      text-decoration: line-through;
    }
  }
}
.NewTask__Component__Reminder {
  padding: 0 20px;

  .NewTask__Component__Reminder__Fields {
    display: flex;
    padding-bottom: 5px;

    & > div {
      width: 140px;
      display: flex;
      flex-grow: 1;
    }
  }
}
.NewTask__Component__Attributes {
  margin: 0 20px;
  padding: 0px 0;
  display: flex;
  border-bottom: 1px solid #d8d8d8;

  ::v-deep .vs__open-indicator {
    fill: #0091ae;
    transform: scale(0.6);
  }
  ::v-deep .vs__selected {
    color: #0091ae;
    font-weight: bold;
  }
  ::v-deep .vs__dropdown-toggle {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: #0091ae;
      cursor: pointer;
    }
  }
}
.Activity__Item__Box__Content__Text__Editor {
  padding-right: 15px;
  margin: 10px 0;
}
.Activity__Item__Box__Content__Text__Editor__Actions {
  margin-bottom: 20px;

  button {
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    cursor: pointer;
    line-height: normal;
    height: unset;
    font-size: 15px;

    &:active {
      transform: scale(0.97);
    }

    &.Save {
      background-color: #486480;
      color: white;
      border-radius: 3px;
      transition: transform 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: #597794;
      }
    }
    &.Cancel {
      background-color: #d0d0d0;
      color: black;
      border-radius: 3px;
      margin-right: 5px;

      &:hover {
        background-color: #e4e4e4;
      }
    }
  }
}
.Activity__Item__Box__Content__Text__Editor {
  direction: rtl;
  padding-right: 14px;
  font-size: 14px;
  position: relative;
  color: black;
  padding: 10px 10px 30px 10px;
  border: 1px solid transparent;
  border-radius: 3px;
  max-height: 55px;
  overflow: hidden;

  &.--Title {
    padding: 0;
    margin: 0;
  }

  &:not(.open)::after {
    background-image: linear-gradient(#04040400 50%, #ffffff);
    height: 55px;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    content: "";
  }
  &.open {
    max-height: unset;
    &:hover {
      background-color: #e5f5f8;
      border: 1px solid #addbff;
    }
  }
}
</style>
