<template>
  <div class="ActivityComment__Component">
      <div class="ActivityComment__Component__Avatar">
          <b-icon icon="account" />
      </div>
      <div class="ActivityComment__Component__Box">
        <div class="ActivityComment__Component__Box__Header">
            <div class="ActivityComment__Component__Box__User">
                <strong>{{item.CreatorName}}</strong>
                הגיב לפוסט זה
                <div class="ActivityComment__Component__Box__User__Actions">
                    <a href="" @click.prevent="editMode=true">עריכה</a>
                    <a href="" @click.prevent="deleteComment">מחיקה</a>
                </div>
            </div>
            <div class="ActivityComment__Component__Box__Date">
                {{parsedDate}}
            </div>
        </div>
        <div class="ActivityComment__Component__Box__Content">
            <div v-html="item.Body" v-if="!editMode"></div>
            <div class="ActivityComment__Component__Box__Content__Edit" v-if="editMode">
                <NewActivityEditor
                    :content="item.Body" ref="editor" animation="''" />
                <div class="ActivityComment__Component__Box__Content__Edit__Actions">
                    <b-button :loading="isLoading" @click="update"  class="Save">שמור</b-button>
                    <b-button :disabled="isLoading"
                        @click="editMode=false" class="Cancel">בטל</b-button>
                </div>
            </div>
        </div>
      </div>

  </div>
</template>

<script>
import moment from 'moment';
import EntityItemsService from '@/services/EntityItemsService';
import NewActivityEditor from './NewActivityEditor.vue';

export default {
    name: 'ActivityComment',
    props: ['item', 'entityType', 'entityId', 'id'],
    data() {
        return {
            editMode: false,
            isLoading: false,
        };
    },
    components: {
        NewActivityEditor,
    },
    computed: {
         parsedDate() {
            if (this.item) {
                const localLocale = moment(this.item.CreateDate);
                localLocale.locale('he');
                return `${localLocale.format('MMM DD, YYYY')} בשעה  ${localLocale.format('HH:mm')}`;
            }
            return null;
        },
    },
    methods: {
        update() {
            this.isLoading = true;
            const body = this.$refs.editor.getHTML();
            EntityItemsService.editComment(
                this.entityType,
                this.entityId,
                this.id,
                this.item.Id, {
                    Body: this.$refs.editor.getHTML(),
                },
            ).then(() => {
                Object.assign(this.item, { Body: body });
                this.editMode = false;
            })
            .finally(() => {
                this.isLoading = true;
            });
        },
        deleteComment() {
             this.$buefy.dialog.confirm({
                title: 'מחיקת תגובה',
                message: 'האם למחוק את התגובה?',
                confirmText: 'כן',
                cancelText: 'לא',
                type: 'is-danger',
                closeOnConfirm: false,
                hasIcon: true,
                onConfirm: (a, instance) => {
                    EntityItemsService.deleteComment(
                        this.entityType,
                        this.entityId,
                        this.id,
                        this.item.Id,
                    ).then(() => {
                        instance.close();
                        this.$emit('onDelete', this.item.Id);
                    });
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.ActivityComment__Component {
    margin-top: 5px;
    display: flex;

    .ActivityComment__Component__Box__Content {
        margin-top: 10px;

        .ActivityComment__Component__Box__Content__Edit {
            .ActivityComment__Component__Box__Content__Edit__Actions {
                margin-top: 10px;

                button {
                border: none;
                background-color: transparent;
                padding: 5px 10px;
                cursor: pointer;
                height: unset;
                line-height: normal;
                font-size: 14px;

                &:active {
                    transform: scale(.97);
                    }

                &.Save {
                    background-color: #486480;
                    color: white;
                    border-radius: 3px;
                    transition: transform .1s cubic-bezier(0.075, 0.82, 0.165, 1);

                    &:hover {
                        background-color: #597794;
                    }
                }
                &.Cancel {
                    background-color: #486480;
                    color: white;
                    border-radius: 3px;
                    margin-right: 5px;

                    &:hover {
                        background-color: #597794;
                    }
                }
                }
            }
        }
    }

    ::v-deep .NewTask__Component__Editor_Inner {
        background-color: #edfaff;
        border: 1px solid #cecece;
        border-radius: 2px;
        .ProseMirror {
            height: 100px;
        }
    }

    .ActivityComment__Component__Avatar {
        background-color: #83d4ce;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-inline-end: 10px;
    }
    .ActivityComment__Component__Box {
        width: 100%;

        .ActivityComment__Component__Box__Header {
            display: flex;
            flex: 1;
            justify-content: space-between;
        }

        .ActivityComment__Component__Box__User {
            font-size: 15px;
            strong {
                font-weight: 600;
                margin-inline-end: 3px;
            }

            .ActivityComment__Component__Box__User__Actions {
                a {
                    color: #06c;
                    margin-inline-end: 6px;
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .ActivityComment__Component__Box__Date {
            font-size: 14px;
        }
    }
}

</style>
